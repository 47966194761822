<!-- 标签聚合页组件 -->
<template>
  <div class="label-list-item">
    <div class="author">
      <div class="author-info" @click="toAuthor()">
        <!-- dataInfo.author.platformUser : 0不是用户  1是用户  不是用户不需要显示头像 -->
        <div class="author-info-avatar" v-if="dataInfo.author.platformUser == 1">
          <img class="avatar" :src="dataInfo.author.avatar || 'https://cdn.9kd.com/kdnet/a6593d8447984c8a9fc798ad5c327a1d.png'" alt="">
          <img class="vip-small" v-if="dataInfo.author.field" src="https://cdn.9kd.com/kdnet/%E8%AE%A4%E8%AF%81%403x.png" alt="">
        </div>
        <div :class="['author-name-time', {'display-flex': dataInfo.author.platformUser == 0}]">
          <div class="author-name">
            <span class="nick-name">{{dataInfo.author.nickname}}</span>
            <img class="vip-big" v-if="dataInfo.author.platformUser == 1 && dataInfo.author.product" src="https://cdn.9kd.com/kdnet/kd-net-VIP.png" alt="">
          </div>
          <div class="author-time">
            {{$timeToString(dataInfo.releaseTime)}}
          </div>
        </div>
      </div>
      <div class="attention" @click="toAuthor()" v-if="dataInfo.author.platformUser == 1">
        +关注
      </div>
    </div>
    <div class="title-discription" ref="titleDiscrition" @click="toDetail()">
      <span class="top-icon" v-if="dataInfo.top">置顶</span>
      <span class="title">{{dataInfo.title}}</span>
      <span class="discription"> 
        <span> {{replaceSpecial(dataInfo.articleJustContent)}}</span>
      </span>
    </div>
    <div class="image-box">
      <!-- 文章 -->
      <div v-if="dataInfo.type == 2 && dataInfo.photos && dataInfo.photos.length" @click="toDetail()">
        <div v-if="dataInfo.photos.length >= 3" class="artical-image height-three">
          <img v-for="(item, index) in dataInfo.photos.slice(0,3)" :key="index" :src="item || 'https://cdn.9kd.com/kdnet/993674ad7f2b41418b6090874f069d4c.png'" alt="">
        </div>
        <div v-else class="artical-image height-one">
          <img :src="dataInfo.photos[0] || 'https://cdn.9kd.com/kdnet/993674ad7f2b41418b6090874f069d4c.png'" alt="" >
        </div>
      </div>
      <!-- 视频 -->
      <div v-if="dataInfo.type == 3"  class="artical-image height-one" @click="toDetail()">
        <img :src="dataInfo.cover" alt="">
        <span class="iconfont iconzanting player-icon"></span>
        <div class="views-time">
          <span v-if="dataInfo.views">
            <span class="iconfont iconliulanliangtubiaowep"></span>
            {{dataInfo.views}} | 
          </span>
          {{$stringVideoTime(dataInfo.times, true)}}
        </div>
      </div>
      <!-- 动态 -->
      <div v-if="dataInfo.type == 10">
        <photos-well :pictures="dataInfo.photos.slice(0,9)" @click="toDetail"></photos-well>
      </div>
    </div>
    <!-- 热评 -->
    <div class="box-review" v-if="dataInfo.commentContent" @click="toAppLabelpage()">
      <div class="box-review-text">
        <img align="bottom" class="icon" src="https://cdn.9kd.com/kdnet/hot-review.png" alt="">
        {{dataInfo.commentContent}}
      </div>
    </div>
    <div class="box-icons">
      <div class="box-icons-item" @click="openShare()">
        <span class="iconfont ico1">&#xe690;</span>
        <span class="btn">分享</span>
      </div>
      <div class="box-icons-item" @click="toAppLabelpage()">
        <span class="iconfont ico2">&#xe69b;</span>
        <span class="btn">评论</span>
        <span v-if="dataInfo.commentTotal">{{dataInfo.commentTotal}}</span>
      </div>
      <div class="box-icons-item" @click="toAppLabelpage()">
        <span class="iconfont ico3">&#xe69c;</span>
        <div class="btn">点赞</div>
        <span v-if="dataInfo.appreciates">{{dataInfo.appreciates}}</span>
      </div>
    </div>
  </div>
</template>
<script>
import PhotosWell from './PhotosWell.vue';
import Bus from '@/utils/bus'
export default {
  components: { PhotosWell },
  props:{
    dataInfo: {
      type: Object,
      default: () => {}
    },
    labelName:{
      type: String,
      default: ''
    }
  },
  mounted() {
    this.refreshText()
  },
  methods:{
    refreshText() {
      let titleDis = this.$refs.titleDiscrition
      if (titleDis && titleDis.getElementsByClassName('discription').length) {
        if (titleDis.getElementsByClassName('discription')[0].offsetHeight > titleDis.offsetHeight) {
          titleDis.innerHTML = titleDis.innerHTML + '<div class="more"><span>...</span>全文</div>'
        }
      }
    },
    /**
     * 跳转标签APP
     */
    toAppLabelpage() {
      let noteInfo = {labelName: this.labelName, sortType: 'new'}
      Bus.$emit(Bus.SHOW_OPEN_DIALOG, { show: true, noteInfo, continuteType: Bus.LABEL_DETAIL });
    },
    /**
     * 跳转作者APP
     */
    toAuthor() {
      Bus.$emit(Bus.SHOW_OPEN_DIALOG, { show: true, noteInfo: this.dataInfo, continuteType: Bus.CONTINUTETYPE_AUTHOR })
    },
    toDetail(index){
      this.$emit('toDetail', this.dataInfo, index);
    },
    openShare() {
      this.$emit('openShare', this.dataInfo);
    },
    replaceSpecial(content){
      return content.replace(/&nbsp;/ig, '')
    }
  }
}
</script>
<style lang="less" scoped>
.label-list-item{
  padding: 0 1px;
}
.author{
  display: flex;
  justify-content: space-between;
  align-items: center;
  .author-info{
    display: flex;
    align-items: center;
    .author-info-avatar{
      position: relative;
      .avatar{
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
      .vip-small{
        position: absolute;
        bottom: 2px;
        right: 2px;
        width: 15px;
      }
    }
    .author-name-time{
      margin-left: 8px;
      &.display-flex{
        display: flex;
        .author-time{
          margin-left: 5px;
        }
      }
      .author-name{
        display: flex;
        align-items: center;
        .nick-name{
          font-size: 15px;
          color: #222;
        }
        .vip-big{
          width: 19px;
          height: 12px;
          margin-left: 6px;
        }
      }
      .author-time{
        margin-top: 3px;
        font-size: 11px;
        color: #999;
      }
    }
  }
  .attention{
    padding: 3px 8px;
    color: #F7321C;
    border: 1px solid #F7321C;
    border-radius: 13px;
    font-size: 13px;
  }
}
.title-discription{
  position: relative;
  margin-top: 12px;
  font-size: 17px;
  line-height: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-all;
  .top-icon{
    padding: 2px 8px;
    margin-right: 5px;
    border-radius: 9px 0 9px 0;
    background: #F7321C;
    color: #fff;
    font-size: 11px;
  }
  .title{
    font-weight: bold;
  }
}
.image-box{
  margin-top: 12px;
  .artical-image{
    position: relative;
    display: flex;
    border-radius: 6px;
    overflow: hidden;
    &.height-three{
      height: 78px;
      justify-content: space-between;
      img{
        width: 32.6%;
      }
    }
    &.height-one{
      height: 195px;
      img{
        display: block;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    .player-icon{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: white;
      font-size: 25px;
    }
    .views-time{
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 40px;
      padding: 0 15px;
      box-sizing: border-box;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
      line-height: 40px;
      text-align: right;
      font-size: 11px;
      color: #fff;
      .iconfont{
        font-size: 11px;
      }
    }
  }
}
.box-review{
  margin-top: 12px;
  padding: 10px 12px;
  background: #F7F7F7;
  font-size: 13px;
  color: #222;
  line-height: 20px;
  
  .box-review-text{
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }
  .icon{
    width: 40px;
    margin-bottom: -2px;
  }
}

.box-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 10px;
  margin-bottom: 16px;
  font-size: 14px;
  border-bottom: 1px solid #F0EDED;
  .box-icons-item {
    display: flex;
    align-items: baseline;
    color: #70767d;
  }

  .ico1 {
    font-size: 15px;
    margin-right: 3px;
  }

  .ico2 {
    font-size: 14px;
    margin-right: 2px;
  }

  .ico3 {
    font-size: 15px;
  }
  .btn{
    margin-right: 2px;
  }
}

</style>
<style>
.title-discription .more{
  position: absolute;
  right: 0;
  bottom: -1px;
  background: white;
  padding: 0 0 0 2px;
  color: #999;
}
.title-discription .more span{
  color: #222;
}
</style>