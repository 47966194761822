<template>
  <div :class="['news-detail']">
    <!--   内容区：未加载完成需要显示加载中   -->
    <div v-if="noteInfo && noteInfo.title === undefined && loadingFlag" style='width: 100vw;height: 100vh;'>
      <van-loading size="44" type="spinner" vertical style='position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%)'>加载中...</van-loading>
    </div>

    <div v-else style='padding: 3px 13px 24px 13px; position: relative; z-index: 0'>
      <news-head
        @on-click-left="onClickLeft"
        @on-click-right="showOpenSelDialog(noteInfo, article)">
      </news-head>

      <p class='news-title' v-html="noteInfo.title && $replaceRegular(noteInfo.title)"></p>
      <div v-if="noteInfo.articleStatus === 1 || noteInfo.articleStatus === 4" class='avatar-info'>
        <div class='p2'>
          <div class="chamber-name">{{ noteInfo.chamberName }}</div>
          <div style='flex-shrink: 0'>{{ noteInfo.publishTs | compareDate }}  {{ noteInfo.ipAddress }} </div>
          <div v-if='noteInfo.views' style='flex-shrink: 0; margin-left: 4px;'>{{ noteInfo.views }}浏览</div>
        </div>
      </div>

      <AliyunVideoPlayer v-if='noteInfo.vid' :vid="noteInfo.vid" vheight="200px" ref="AliyunVideoPlayerRef" />

      <div v-if="noteInfo.articleStatus === 1 || noteInfo.articleStatus === 4" :class="['news-content']" v-html='noteInfo.contentHtml'></div>
      <div v-if="noteInfo.articleStatus === 0 || noteInfo.articleStatus === 3 || noteInfo.articleStatus === 5" class="freeze">
        <img src='https://ysh-cdn.kaidicloud.com/test/kd/2024/01/31/80be6b6fbbc04eeb8875b17c2b977aa8.png' alt=''>
        数据异常
      </div>
    </div>

    <!-- 底部按钮，唤起APP/下载APP -->
    <open-app-button
      :noteInfo="noteInfo"
      @openApp="(continuteType) =>showOpenSelDialog(noteInfo, continuteType)"
    />

  </div>
</template>

<script>
import Bus from '../../utils/bus'
import storage from '../../utils/saveLocal'
export default {
  name: 'noteDetail',
  components:{
    NewsHead: () => import('../../components/NewsHead.vue'),
    ArticleContent: () => import('../../components/ArticleContent.vue'),
    OpenAppButton: () => import('../../components/OpenAppButton.vue'),
    AliyunVideoPlayer: () => import('./components/aliyunVideoPlayer/index.vue')
  },
  data() {
    return {
      noteInfo: {},
      newOrNote: 1,
      lastQueryOneTime: 0,
      article: Bus.CONTINUTETYPE_ARTICLE,
      loadingFlag: false, // 加载转菊花标志
      loadingTimer: null, // 加载转菊花标志
    }
  },
  watch: {
    '$route'() {
      this.queryone()
    }
  },
  created() {
    Bus.$emit(Bus.SHOW_DOWN_FLOATING, false)
    Bus.$emit(Bus.SHOW_LOOP_FLOATING, true)
    this.queryone()
  },
  mounted() {
    Bus.$on(Bus.UPDATE_NEW_INFO, () => {
      this.queryone()
    })
    window.addEventListener('scroll', this.scrollHandle)

    /* 处理转菊花效果 */
    this.loadingTimer = setTimeout(() => {
      this.loadingFlag = true
    }, 200)

  },
  beforeDestroy() {
    clearInterval(this.loadingTimer)
    Bus.$off(Bus.UPDATE_NEW_INFO)
    window.removeEventListener('scroll', this.scrollHandle)


    clearInterval(this.interTimer);
  },
  methods: {
    // 对ISO不支持webp格式图片的临时处理
    formatImgForWebp(mydata) {
      return mydata.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi, function (match,capture) {
        return match.replace(/src=['"]([^'"]+)[^>]*>/gi, function(match1,r) {
          if (r?.includes('.gif')) return match1
          return match1 = `src="${r}?x-oss-process=image/format,jpg">`
        })
      });
    },

    initExprose() {
      this.$nextTick(() => {
        this.scrollHandle()
        var timer = setTimeout(() => {
          this.scrollHandle()
          clearTimeout(timer)
        }, 1000)
      })
    },
    // 查询文章详情
    async queryone() {
      let nowTime = new Date().getTime()
      if (nowTime - this.lastQueryOneTime < 500) {
        return
      }
      this.lastQueryOneTime = nowTime
      let { data: res } = await this.$api.queryArticleDetail({
        articleId: this.$route.params.id,
        type: this.$route.query.type,
        userId: ''
      })
      if (res.code !== 200) return;
      // webp格式的图片处理
      if (this.$Is_IOS) {
        res.data.contentHtml = this.formatImgForWebp(res.data.contentHtml)
      }
      this.noteInfo = res.data

      var videoWidth = document.body.offsetWidth - 26
      var videoHeight = videoWidth / 16 * 9

      // 去除img标签的内联样式
      function formatIframe(html) {
        var newContent = html.replace(/<iframe[^>]*>/ig, function(match, capture) {
          var match = match.replace(/(width="(.*?)")/ig, `width="${videoWidth}"`)
          match = match.replace(/(height="(.*?)")/ig, `height="${videoHeight}"`)
          return match
        })
        newContent = newContent.replace(/<img/ig, '<img class=refimgs')
        return newContent
      }
      this.noteInfo.contentHtml = formatIframe(this.noteInfo.contentHtml)
      this.newOrNote = res.data.type;
    },

    // 打开App选择器
    showOpenSelDialog(noteInfo, continuteType) {
      Bus.$emit(Bus.SHOW_OPEN_DIALOG, { show: true, noteInfo: noteInfo, continuteType: continuteType });
    },
    /**
     * 点击导航顶部左侧返回首页
     */
    onClickLeft() {
      Bus.$emit(Bus.SHOW_LOOP_FLOATING, false)
      this.$router.push({
        path: '/'
      })
    },
    /**
     * 滚动函数
     */
    scrollHandle() {
      let subHeight = 0
      if (document.getElementById('foot_loop_box')) {
        subHeight = document.getElementById('foot_loop_box').offsetHeight
      }
      if (document.getElementById('foot-open-box')) {
        subHeight = document.getElementById('foot-open-box').offsetHeight
      }
    },
  }
}
</script>

<style lang='less'>
.news-detail {
  word-wrap: break-word;
  overflow: hidden;
}

.news-title {
  font-size: 20px;
  font-weight: bold;
  color: #242831;
  line-height: 30px;
  letter-spacing: 1px;
}

.avatar-info {
  color: #3a3e45;
  font-size: 13px;

  .p2 {
    display: flex;
    align-items: center;
    font-size: 10px;
    color: #9099a6;
  }

  .chamber-name {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    word-break: break-all;// 纯数字不换行
    margin-right: 4px;
  }
}

.freeze {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  color: #999999;
  line-height: 25px;
  padding-top: 60px;

  img {
    width: 210px;
    height: 206px;
  }
}

.news-content {
  font-size: 16px;
  color: #414449;
  letter-spacing: 1px;
  position: relative;
  box-sizing: border-box;
  padding-top: 8px;

  .aliyun-replay-show_duration {
    width: 64px;
    height: 64px;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 999;
    text-align: center;
    line-height: 64px;
    color: #fff;
  }

  .aliyun-replay-btn {
    position: absolute;
    z-index: 100;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;

    .aliyun-replay-icon {
      text-align: center;
      .iconfont {
        color: white;
        font-size: 25px;
      }

      .aliyun-replay-text {
        color: white;
        font-size: 13px;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        border: 1px solid #ccc;
        padding: 4px 10px 5px 10px;
      }
    }
  }

  ul {
    max-width: 100% !important;
    padding: 0px !important;

    * {
      max-width: 100% !important;
      padding: 0px !important;
    }
  }

  p {
    margin-bottom: 15px;
    line-height: 25px;
  }

  img {
    width: 100% !important;
    height: auto !important;
  }

}
</style>
