<template>
    <div v-if="pictures.length" ref="photosWell" @click="pictureHandler($event)" :class="[
        'photos-well', 
        {
            'photos-well-radius': radius,
            'warp-cover': pictures.length == 1,
            'warp-between': pictures.length >= 3 && pictures.length != 4,
            'photos-well-two': pictures.length == 2 || pictures.length == 4,
            'photos-well-special': pictures.length >= 5,
            'photos-well-special-two': pictures.length > 4 && (pictures.length % 3 === 1),
            'photos-well-special-three': pictures.length > 4 && (pictures.length % 3 === 2),

            'photos-well-special-four': pictures.length == 4,
        }
    ]">
        <div class="photos-well-item" v-for="(item,index) in pictures" :key="index">
            <img class="pwi-img" :height="height" :data-index="index" data-item="item" :src="item || 'https://cdn.9kd.com/kdnet/993674ad7f2b41418b6090874f069d4c.png'" alt="">
        </div>
    </div>
</template>
<script>
export default {
    name:"PhotosWell",
    props:{
        pictures:{
            type: Array,
            default: () => []
        },
        radius: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            height: 100,
        }
    },
    mounted() {
        this.initPictureHeight();
    },
    methods: {
        initPictureHeight() {
            if (this.pictures.length === 1) {
                this.height = 194
            } else {
                this.height = 110
            }
        },
        pictureHandler(event) {
            let index = event.target.getAttribute('data-index') || 0
            this.$emit('click', index)
        }
    },
    watch:{
        // 监听数据的变化来改变图片的高度
        pictures() {
            this.initPictureHeight()
        }
    }
}
</script>
<style>
    .photos-well{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }
    .photos-well-two{
        justify-content: space-between;
        width: 100%;
        padding-right: 32%;
        box-sizing: border-box;
        border-radius: 6px;
        overflow: hidden;
    }
    .photos-well-item{
        overflow: hidden;
    }
    .photos-well-two .photos-well-item{
        width: 49.4%;
    }
    .warp-cover .photos-well-item{
        width: 194px;
    }
    .warp-cover .photos-well-item .pwi-img{
        border-radius: 6px;
    }
    .warp-between{
        border-radius: 6px;
        overflow: hidden;
    }
    .warp-between .photos-well-item{
        width: 32.7%;
    }
    .warp-between .photos-well-item:nth-child(3n+2){
        margin:0 0.8%;
    }
    .photos-well-special .photos-well-item:nth-child(3n+2){
        margin-bottom: 0.8%;
    }
    .photos-well-special-four .photos-well-item:nth-child(2){
        margin-bottom: 1.1%;
    }
    .photos-well-special .photos-well-item:last-child,
    .photos-well-special-two .photos-well-item:nth-last-child(2){
        border-radius: 0 0 6px 0;
    }
    .photos-well-special-three .photos-well-item:nth-last-child(3){
        border-radius: 0 0 6px 0;
    }
    .photos-well-item .pwi-img{
        display: block;
        width: 100%;
        object-fit: cover;
        cursor: pointer;
    }
    .photos-well-radius .photos-well-item .pwi-img{
        border-radius: 6px;
    }
    
</style>