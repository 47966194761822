// 进度条
import { ProgressComponent } from './videoProgress'

/**
 * 文档连接：https://help.aliyun.com/document_detail/125547.html
 * @param {*} id 容器id
 * @param {*} vid 视频id
 * @param {*} accessKeyId STS的accessKeyId
 * @param {*} accessKeySecret STS的accessKeySecret
 * @param {*} securityToken STS的securityToken
 * @param {*} cover 封面
 * @param {*} region 视频播放的地区
 * @param {*} videoHeight 视频的高度
 * @param {*} setVideoHeight 是否需要重新设置视频容器的高度
 * @returns 
 */
export default function ali(id,vid,accessKeyId,accessKeySecret,securityToken,cover,region,videoHeight) {
  const player = document.getElementById(id);
  if(!player){
    return
  }
  if(!Aliplayer){
    return
  }
  return new Aliplayer({
    id,
    width: '100%',
    height: videoHeight + 'px',
    videoWidth: "100%",
    autoplay: false,
    useH5Prism: true,
    mediaType: "video",
    playsinline: true,
    showBarTime:"4000",
    controlBarVisibility:'alway',
    cover,
    skinLayout:[
      {name: "bigPlayButton", align: "blabs", x: 'calc(50% - 32px)', y: 'calc(50% - 32px)'},
      {name: "H5Loading", align: "cc"},
      {name: "errorDisplay", align: "tlabs", x: 0, y: 0},
      {name: "infoDisplay"},
      {name:"tooltip", align:"blabs",x: 0, y: 56},                // 悬浮在按钮上的提示
      {name: "thumbnail"},
      {
        name: "controlBar", align: "blabs", x: 0, y: 0,           // 控制视频的控件
        children: [
          {name: "playButton", align: "tl", x: 15, y: 12},        // 播放按钮
          {name: "timeDisplay", align: "tl", x: 10, y: 3},        // 时间线
          {name: "fullScreenButton", align: "tr", x: 10, y: 12},  // 全屏播放按钮
          {name: "volume", align: "tr", x: 5, y: 10},             // 音量
        ]
      }
    ],
    // 自定义进度条组件
    components:[
      {name:'ProgressComponent', type: ProgressComponent},
    ],
    //播放方式四：使用STS方式播放
    vid,
    accessKeyId,
    securityToken,
    accessKeySecret,
    region
  }, function(player){
  });
}
