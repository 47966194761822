<template>
  <div :class="['news-detail']">
    <!--   内容区：未加载完成需要显示加载中   -->
    <div v-if="noteInfo && noteInfo.title == undefined && loadingFlag" style='width: 100vw;height: 100vh;'>
      <van-loading size="44" type="spinner" vertical style='position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%)'>加载中...</van-loading>
    </div>
    
    <div v-else>
      <picture-wrap 
        v-if="noteInfo.type === 6"
        :noteInfo="noteInfo"
        :images="images">
      </picture-wrap>

      <div v-else :style="{'margin-bottom': noteInfo.status === 2 ? '60px' : ''}">
        <news-head
          @on-click-left="onClickLeft"
          @on-click-right="showOpenSelDialog(noteInfo, article)">
        </news-head>
        <!-- 待审核状态为：1, 91,95,97 -->
        <examine v-if="loadingFlag && noteInfo.status != 2"></examine>
        <!--  正常看内容状态为： 2 -->
        <div v-else>
          <!-- 内容区：文章或者帖子 -->
          <article-content ref="articleContent" v-if="noteInfo.type != 3 && noteInfo.content"
            :noteInfo="noteInfo"
            :newOrNote="newOrNote"
            :goodsData="goodsData"
            @clickHandler="openAppLabel"
            @toGoodsDetail="toGoodsDetail"
            @showOpenApp="showOpenSelDialog">
          </article-content>

          <!-- 内容区：视频 -->
          <video-content v-if="noteInfo.type == 3"
            :noteInfo="noteInfo"
            :goodsData="goodsData"
            @toGoodsDetail="toGoodsDetail"
            @clickHandler="openAppLabel"
            @showOpenApp="showOpenSelDialog">
          </video-content>

          <!-- 评论区 -->
          <comment-list
            :commentsData="commentsData"
            :noteInfo="noteInfo"
            :commentType="'article'"
            @show-open-select-dialog="showOpenSelDialog">
          </comment-list>

          <!-- 文中广告位置 -->
          <div class='adv-read-comment-pre' v-if="recommendRequest && recomentAdv && advMap[recomentAdv.onlyId]['isShow']">
            <div @click='openAdvLink(recomentAdv.adUrl)'>
              <div class='adv-read-comment-title'>{{ recomentAdv ? recomentAdv.title : '' }}</div>
              <div class='adv-read-comment-img' v-bind='{ id: recomentAdv.onlyId }' ref='recomentAdvListItem'>
                <img :src='recomentAdv.pictures[0]' @error='errorImgShow(recomentAdv.onlyId)' alt=''>
                <div class='adv-read-comment-tip'> 广告 </div>
              </div>
            </div>
          </div>

          <!--   文章推荐区   -->
          <recommend-list ref="recomendListBox"
            :recommend="recommend"
            :recommendRequest="recommendRequest"
            :advMap="advMap"
            :existAdv="existAdv"
            @openAdvLink="openAdvLink"
            @queryoneAndShowOpen="queryoneAndShowOpen"
            @errorImg="errorImgShow">
          </recommend-list>
        </div>
        
      </div>
    </div>

    <!-- 读帖浮层 -->
    <read-post-button  v-if='(noteInfo.type !== 6 && noteInfo.type !== 3 && noteInfo.status == 2)'
      :ttsRecorder="$ttsRecorder"
      :startflag="startflag"
      :composeSuccessed="composeSuccessed"
      @btts="btts"
      @stop-btts="stopbtts"
      @select-voice="onSelect">
    </read-post-button>

    <!-- 底部按钮，唤起APP/下载APP -->
    <open-app-button 
      :noteInfo="noteInfo" 
      @openApp="(continuteType) =>showOpenSelDialog(noteInfo, continuteType)">
    </open-app-button>

  </div>
</template>

<script>
import Bus from '../../utils/bus'
import storage from '../../utils/saveLocal'
import Aliplayer from '../../utils/Aliplayer'
import encryp from '../../utils/encryp'
import ArticleContent from '../../components/ArticleContent.vue'
import RecommendList from '../../components/RecommendList.vue'
import Examine from '../../components/Examine.vue'
export default {
  name: 'noteDetail',
  components:{
    NewsHead: () => import('../../components/NewsHead.vue'),
    ArticleContent: ArticleContent,
    VideoContent: () => import('../../components/VideoContent.vue'),
    CommentList: () => import('../../components/CommentList.vue'),
    ReadPostButton: () => import('../../components/ReadPostButton.vue'),
    RecommendList,
    OpenAppButton: () => import('../../components/OpenAppButton.vue'),
    PictureWrap: () => import('../../components/PictureWrap.vue'),
    Examine
  },
  data() {
    return {
      existAdv: false,
      ttsarry: storage.get('limit_three') ? storage.get('limit_three') : [],//限制读3篇帖子
      startflag: false,
      shareVoiceName: {
        xiaoyu: '普通话(男声)',
        xiaoyan: '普通话(女声)',
        vinn: '普通话(童声)',
        vils: '普通话(老年)',
        vixm: '粤语',
        vixl: '台湾话',
        vixr: '四川话',
        vixyun: '东北话',
        vixk: '河南话',
        vixqa: '湖南话',
        vixying: '陕西话'
      },
      images: [],
      noteInfo: {
        author: {}
      },
      newOrNote: 1,
      //帖子评论
      commentsData: [],
      // 推荐阅读
      recommend: [],
      lastQueryOneTime: 0,
      composeSuccessed: false,
      advMap: {},
      recomentAdv: null,
      recommendRequest: false,
      article: Bus.CONTINUTETYPE_ARTICLE,
      videoPlayerMap: {},
      pureVideo: null,// 纯视频
      videoPlayTime: 0,
      goodsData:null, // 关联商品数据
      loadingFlag: false, // 加载转菊花标志
      loadingTimer: null, // 加载转菊花标志
    }
  },
  watch: {
    '$route'() {
      this.queryone()
      this.queryCommunityCommentLists()
      this.queryRecommendedReadingData()
    }
  },
  created() {
    Bus.$emit(Bus.SHOW_DOWN_FLOATING, false)
    Bus.$emit(Bus.SHOW_LOOP_FLOATING, true)
    this.isolympic()
    this.queryone()
    this.queryCommunityCommentLists()
    this.queryRecommendedReadingData()
    this.queryWxConfigs();
    // 获取商品信息
    this.getTaskInfo();
  },
  mounted() {
    Bus.$on(Bus.UPDATE_NEW_INFO, () => {
      this.queryone()
    })
    //获取url参数
    if (this.getUrlParam('source')) {
      this.$ttsRecorder.setParams({
        voiceName: this.getUrlParam('source')
      })
    }
    window.addEventListener('scroll', this.scrollHandle)

    this.intervalGetPlayTime();

    /* 处理转菊花效果 */
    this.loadingTimer = setTimeout(() => {
      this.loadingFlag = true
    }, 200)

  },
  beforeDestroy() {
    clearInterval(this.loadingTimer)
    Bus.$off(Bus.UPDATE_NEW_INFO)
    this.$ttsRecorder.stop()
    this.$ttsRecorder.resetAudio()
    window.removeEventListener('scroll', this.scrollHandle)

    if (this.pureVideo) {
      this.pureVideo.dispose() //销毁
      this.pureVideo = null
    }
    clearInterval(this.interTimer);
  },
  methods: {
    // 打开App选择器
    openAppLabel(labelName) {
      let noteInfo = {labelName,sortType: 'new'}
      Bus.$emit(Bus.SHOW_OPEN_DIALOG, { show: true, noteInfo, continuteType: Bus.LABEL_DETAIL });
    },
    // 对ISO不支持webp格式图片的临时处理
    formatImgForWebp(mydata) {
      return mydata.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi, function (match,capture) {
        return match.replace(/src=['"]([^'"]+)[^>]*>/gi, function(match1,r) {
          if (r.includes('.gif')) return match1
          return match1 = `src="${r}?x-oss-process=image/format,jpg">`
        })
      });
    },
    // 是否奥运过来的文章
    isolympic() {
      // 变化为奥运的样式
      let bol = this.$route.query.from_id === 'oly' ? false : true
      this.$store.commit('changeDownloadCss',bol)
    },
    /**
     * 切换语音
     */
    onSelect(item, index) {
      this.$ttsRecorder.stop()
      this.$ttsRecorder.resetAudio()
      this.composeSuccessed = false
      this.startflag = false
      this.$ttsRecorder.setParams({
        voiceName: item.language,
        text: this.noteInfo.content.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi, '').replace(/<[^>]+?>/g, '').replace(/\s+/g, '').replace(/ /g, '').replace(/>/g, '').replace(/[ ]|[&nbsp;]/g, ''),
        composeCallback: this.composeCallback,
        endCallback: this.endCallback
      })
      this.queryWxConfigs(this.$ttsRecorder.voiceName);
      setTimeout(() => {
        if (this.ttsarry.indexOf(this.noteInfo.id) === -1) { // 是新的一篇帖子
          if (this.ttsarry.length >= 3) { // 已经读过3篇文章
            this.$toast('已使用3篇正文语音阅读，去凯迪APP体验更佳')
            setTimeout(() => {
              this.$router.push('/download')
            }, 3000)
            return
          } else {
            this.ttsarry.push(this.noteInfo.id)
            storage.set('limit_three', this.ttsarry)
            // 第一次来，不满足3次，读帖
            this.$ttsRecorder.start()
            this.startflag = true
          }
        } else {
          // 曾经读过的文章，可以继续读
          this.$ttsRecorder.start()
          this.startflag = true
        }
      }, 500)
    },
    btts() {
      this.queryWxConfigs(this.$ttsRecorder.voiceName)
      if (this.ttsarry.indexOf(this.noteInfo.id) === -1) { // 是新的一篇帖子
        if (this.ttsarry.length >= 3) { // 已经读过3篇文章
          this.$toast('已使用3篇正文语音阅读，去凯迪APP体验更佳')
          setTimeout(() => {
            this.$router.push('/download')
          }, 3000)
          return
        } else {
          this.ttsarry.push(this.noteInfo.id)
          storage.set('limit_three', this.ttsarry)
          // 第一次来，不满足3次，读帖
          this.readerNote()
        }
      } else {
        // 曾经读过的文章，可以继续读
        this.readerNote()
      }
    },
    // 读帖
    readerNote() {
      for (let videoId in this.videoPlayerMap) {
        (this.videoPlayerMap[videoId].getStatus() === 'playing') && this.videoPlayerMap[videoId].pause()
      }
      if(this.pureVideo){
        this.pureVideo.getStatus() === 'playing' && this.pureVideo.pause()
      }
      if(this.noteInfo.type == 3) {
        this.$toast('当前为视频，无法读帖');
        return
      }
      this.$ttsRecorder.setParams({
        text: this.noteInfo.content.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi, '').replace(/<[^>]+?>/g, '').replace(/\s+/g, '').replace(/ /g, '').replace(/>/g, '').replace(/[ ]|[&nbsp;]/g, ''),
        composeCallback: this.composeCallback,
        endCallback: this.endCallback
      })
      this.startflag = true
      if (
        ['init', 'endPlay', 'errorTTS'].indexOf(this.$ttsRecorder.status) > -1
      ) {
        this.$ttsRecorder.start()
      } else {
        this.$ttsRecorder.stop()
      }
    },
    stopbtts() {
      this.$ttsRecorder.stop()
      this.$ttsRecorder.resetAudio()
      this.startflag = false
    },
    composeCallback(status) {
      if (status == 'successTTS') {
        this.composeSuccessed = true
      } else {
        this.composeSuccessed = false
      }
    },
    endCallback() {
      this.startflag = false
    },
    // 社区详情滚动到底部
    scrollB() {
      this.$nextTick(() => {
        window.scrollTo(0, document.documentElement.clientHeight)
      })
      window.scrollTo(0, document.documentElement.clientHeight)
    },
    // 请求后台接口获取
    async queryWxConfigs(val) {
      // 是否是奥运的文章
      var url = '';
      if(val) {
        url = window.location.href + (window.location.search ? '&source=' + val : '?source=' + val)
      }else{
        url = window.location.href
      }
      let { data: result } = await this.$api.queryone(this.$route.params.id)
      let sharedata = {}
      if (result.code == 200) {
        sharedata = result.data;
        sharedata.content = sharedata.content.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, '') // 去掉所有html标记的函数
      }
      // 全局混入函数
      this.setWeChatShare({
        url: url,
        title: val ? `「${this.shareVoiceName[this.$ttsRecorder.voiceName]}听更有趣」` + sharedata.title : sharedata.title,
        description: sharedata.content ? sharedata.content : '',
        friendImg: sharedata.firstPicture ? sharedata.firstPicture : this.$baseInfo.LOGO_IMG,
        momentsImg: sharedata.firstPicture ? sharedata.firstPicture : this.$baseInfo.MOMENTS_LOGO_IMG
      });
    },
    // 推荐阅读
    queryRecommendedReadingData() {
      this.$api.queryRecommendedReading({ articleId: this.$route.params.id, limit: 20 }).then(res => {
        let data = res.data
        if (data.code !== 200) return
        this.recommend = data.data
        this.recommendRequest = true
        this.queryAdvDetail(2);
      }).catch(err => {
        this.$toast.fail(err)
      })
    },
    /**
     * a_Type
     * 1：文章中部
     * 2：文章推荐阅读
     */
    queryAdvDetail(a_Type) {
      this.$api.queryAdvContent({
        'articleId': parseInt(this.$route.params.id),
        'extend': '',
        'product': this.noteInfo.product,
        'system': '',
        'type': a_Type == 1 ? 'WAP2' : 'WAP3',
        'unicode': '',
        'version': ''
      }).then(res => {
        if (res.data.code == 200 && res.data.data) {
          if (a_Type == 1) {
            res.data.data['onlyId'] = Math.random().toString().slice(2)
            res.data.data['isShow'] = true
            this.recomentAdv = res.data.data
            this.advMap[res.data.data['onlyId']] = res.data.data
            this.initExprose()
          } else if (a_Type == 2) {
            if (res.data.data.length) {
              if (res.data.data[0].position === 1) {
                this.existAdv = true
              } else {
                this.existAdv = false
              }
              for (let i = 0; i < res.data.data.length; i++) {
                res.data.data[i]['isShow'] = true
                res.data.data[i]['isAdv'] = true
                res.data.data[i]['onlyId'] = Math.random().toString().slice(2)
                this.recommend.splice(res.data.data[i].position - 1, 0, res.data.data[i])
                this.advMap[res.data.data[i]['onlyId']] = res.data.data[i]
              }
              this.initExprose()
            }
          }
        } else {
          if (a_Type == 1) {
            this.recomentAdv = null
          }
        }

      }).catch(error => {
        console.log(error)
      })
    },
    initExprose() {
      this.$nextTick(() => {
        this.scrollHandle()
        var timer = setTimeout(() => {
          this.scrollHandle()
          clearTimeout(timer)
        }, 1000)
      })
    },
    //查询评论(帖子和文章同一个接口)
    queryCommunityCommentLists() {
      let params = {
        articleId: this.$route.params.id,
        limit: 3,
        onlyAuthor: 0,
        page: 1
      }
      this.$api.queryCommunityCommentList(params).then(res => {
        let data = res.data;
        if (data.code === 321) {
          return
        }
        if (data.code !== 200) return
        this.commentsData = data.data.records
      })
    },
    // 查询文章详情
    async queryone() {
      let nowTime = new Date().getTime()
      if (nowTime - this.lastQueryOneTime < 500) {
        return
      }
      this.lastQueryOneTime = nowTime
      let { data: res } = await this.$api.queryone(this.$route.params.id)
      if (res.code === 302 || res.code === 379) {
        this.$router.push('/notfound');
      }
      if (res.code !== 200) return;
      // webp格式的图片处理
      if (this.$Is_IOS) {
        res.data.content = this.formatImgForWebp(res.data.content)
      }
      this.images = res.data.pictures
      this.noteInfo = res.data
      var videoWidth = document.body.offsetWidth - 26
      var videoHeight = videoWidth / 16 * 9

      // 去除img标签的内联样式
      function formatIframe(html) {
        var newContent = html.replace(/<iframe[^>]*>/ig, function(match, capture) {
          var match = match.replace(/(width="(.*?)")/ig, `width="${videoWidth}"`)
          match = match.replace(/(height="(.*?)")/ig, `height="${videoHeight}"`)
          return match
        })
        newContent = newContent.replace(/<img/ig, '<img class=refimgs')
        return newContent
      }
      this.noteInfo.content = formatIframe(this.noteInfo.content)
      this.queryAdvDetail(1)
      this.$ttsRecorder.defaultText = res.data.content.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi, '').replace(/<[^>]+?>/g, '').replace(/\s+/g, ' ').replace(/ /g, ' ').replace(/>/g, ' ')
      this.newOrNote = res.data.type;
      this.$route.query.comment && this.newOrNote === 1 && res.data.comments !== 0 && this.scrollB()
      if (this.noteInfo.type === 6) return
      var videoHeight = this.$Is_PC ? (500-26) / 16 * 9 : (document.body.clientWidth - 26) / 16 * 9;
      if(this.noteInfo.type == 3) {
        this.noteInfo.status === 2 && this.getVideoDetailInfo()
      } else {
        this.$nextTick(async () => {
          if(this.$refs.articleContent && this.$refs.articleContent.$refs.textContent) {
            let videoClass = this.$refs.articleContent.$refs.textContent.getElementsByClassName('aliyun-video-player')
            let length = videoClass.length
            if (length > 0) {
              let { data: res } = await this.$api.stsToken()
              for (let i = 0; i < length; i++) {
                videoClass[i].innerHTML = ''
                let shipindid = videoClass[i].getAttribute('data-kd-videoid')
                this.videoPlayerMap[videoClass[i].dataset['kdVideoid']] = Aliplayer(
                  videoClass[i].getAttribute('id'),
                  shipindid,
                  encryp(res.data.accessKeyId),
                  encryp(res.data.accessKeySecret),
                  encryp(res.data.securityToken),
                  videoClass[i].dataset['kdVideocover'],
                  encryp(res.data.region),
                  videoHeight
                )
                this.$api.video_time(shipindid).then(res => {
                  let show_duration = document.createElement('div')
                  show_duration.className = 'aliyun-replay-show_duration'
                  show_duration.innerText = Math.floor(res.data.data.duration / 60).toString().padStart(2, '0') + ':' + Math.floor(res.data.data.duration % 60)
                  videoClass[i].appendChild(show_duration)
                }).catch(err => {
                  console.log('视频时长接口报错')
                })
              }
              this.handleVideo()
            }
          }
          // 检查全部加载图片和图片预览
          this.$refs.articleContent && this.$refs.articleContent.setImageMore();
        })
      }
    },
    /**
     * 获取视频详情数据
     */
    async getVideoDetailInfo(){
      let { data: res } = await this.$api.stsToken();
      let id = this.$route.params.id;
      let { data: messageeInfo} = await this.$api.getVideoInfo(id)
      if(messageeInfo.code != 200) {
        this.$router.push('/');
        return
      }
      let clientWidth = document.getElementsByClassName('news-detail')[0].clientWidth;
      let defultHeight = clientWidth / 16 * 9; 
      let bl = messageeInfo.data.width ? (clientWidth / messageeInfo.data.width) : 1;
      let videoHeight = messageeInfo.data.width ? (messageeInfo.data.height * bl) : defultHeight;
      this.pureVideo = Aliplayer(
        'pure-video-box',
        messageeInfo.data.vid,
        encryp(res.data.accessKeyId),
        encryp(res.data.accessKeySecret),
        encryp(res.data.securityToken),
        messageeInfo.data.img,
        encryp(res.data.region),
        videoHeight
      )
      let player = document.querySelector('#pure-video-box')
      let show_duration = document.createElement('div')
      show_duration.className = 'aliyun-replay-show_duration'
      show_duration.innerText =
        Math.floor(messageeInfo.data.time / 60).toString().padStart(2, '0') +
        ':' +
        Math.floor(messageeInfo.data.time % 60).toString().padStart(2, '0')
      player.appendChild(show_duration)
      this.handleVideo()
    },
    /* 视频处理 */
    handleVideo() {
      var self = this
      for (let videoId in this.videoPlayerMap) {
        // 播放完成，重播
        this.videoPlayerMap[videoId].on('ended', (e) => {
          var vid = e.target.dataset['kdVideoid']
          var replayBox = document.createElement('div')
          replayBox.className = 'aliyun-replay-btn'
          replayBox.style.width = window.getComputedStyle(e.target).width
          replayBox.style.height = window.getComputedStyle(e.target).height
          replayBox.innerHTML = '<div class="aliyun-replay-icon"><span class="iconfont iconreplay_icon2x"></span><div class="aliyun-replay-text">重播</div></div>'
          e.target.appendChild(replayBox)
          e.target.getElementsByClassName('prism-big-play-btn')[0].style.display = 'none'
          replayBox.firstElementChild.onclick = function() {
            replayBox.remove()
            self.videoPlayerMap[vid].seek(0)
            self.videoPlayerMap[vid].replay()
          }
        })

        // 处理同时只能播放一个视频/如果有重播按钮，去掉
        this.videoPlayerMap[videoId].on('play', (e) => {
          // 关闭语音
          this.stopbtts()
          var curVideoId = e.target.dataset.kdVideoid
          for (let key in this.videoPlayerMap) {
            if (key != curVideoId) {
              this.videoPlayerMap[key].pause()
            }
          }
          var replayBtn = e.target.getElementsByClassName('aliyun-replay-btn')
          if (replayBtn.length) {
            replayBtn[0].remove()
          }
          let show_duration = e.target.getElementsByClassName('aliyun-replay-show_duration');
          (show_duration.length) && (show_duration[0].parentNode.removeChild(show_duration[0]))
        })
        this.videoPlayerMap[videoId].on('canplay', (e) => {
          var replayBtn = e.target.getElementsByClassName('aliyun-replay-btn')
          if (replayBtn.length) {
            replayBtn[0].remove()
          }
        })
      }

      if(this.pureVideo){
        // 播放完成，重播
        this.pureVideo.on('ended', (e) => {
          var replayBox = document.createElement('div')
          replayBox.className = 'aliyun-replay-btn'
          replayBox.style.width = window.getComputedStyle(e.target).width
          replayBox.style.height = window.getComputedStyle(e.target).height
          replayBox.innerHTML = '<div class="aliyun-replay-icon"><span class="iconfont icon-replay_icon2x"></span><div class="aliyun-replay-text">重播</div></div>'
          e.target.appendChild(replayBox)
          e.target.getElementsByClassName('prism-big-play-btn')[0].style.display = 'none'
          replayBox.firstElementChild.onclick = function () {
            replayBox.remove()
            self.pureVideo.seek(0)
            self.pureVideo.replay()
          }
        })
        // 如果有重播按钮，去掉
        this.pureVideo.on('play', (e) => {
          // this.pureVideo._el.style.height = this.pureVideo.tag.offsetHeight + 'px';
          // this.pureVideo._el.style.visibility = 'visible';
          // 关闭语音
          this.stopbtts()
          var replayBtn = e.target.getElementsByClassName('aliyun-replay-btn')
          if (replayBtn.length) {
            replayBtn[0].remove()
          }
          let show_duration = e.target.getElementsByClassName('aliyun-replay-show_duration')
          show_duration.length && show_duration[0].parentNode.removeChild(show_duration[0])

          // 上报播放量
          if(!this.isFirstPlay) {
            this.isFirstPlay = true;
            this.postVideoPlayView()
          }
        })
        this.pureVideo.on('canplay', (e) => {
          var replayBtn = e.target.getElementsByClassName('aliyun-replay-btn')
          if (replayBtn.length) {
            replayBtn[0].remove()
          }
        })
      }
    },
    /**
     * 记录播放视频时间
     */
    intervalGetPlayTime() {
      this.interTimer = setInterval(() => {
        if(this.pureVideo && this.pureVideo.getStatus() === 'playing') {
          this.videoPlayTime += 1;
        }
        if (this.videoPlayTime >= 10) {
          this.postVideoPlayView();
          clearInterval(this.interTimer);
        }
      }, 1000);
    },
    // 查询文章详情
    queryoneAndShowOpen(noteInfo, continuteType, index) {
      this.$api.queryone(noteInfo.id).then(res => {
        let data = res.data
        if (data.code !== 200) {
          this.$notify({ type: 'primary', message: '文章查询失败' })
          return
        }
        if (data.data.id === noteInfo.id) {
          if (index < (this.existAdv ? 4 : 3) || index === undefined) {
            this.showOpenSelDialog(data.data, continuteType)
          } else {
            this.$ttsRecorder.stop()
            this.$ttsRecorder.resetAudio()
            this.startflag = false
            this.$router.push(`/note/${data.data.id}`)
          }
        }
      })
    },
    // 打开App选择器
    showOpenSelDialog(noteInfo, continuteType) {
      Bus.$emit(Bus.SHOW_OPEN_DIALOG, { show: true, noteInfo: noteInfo, continuteType: continuteType });
    },
    /**
     * 点击导航顶部左侧返回首页
     */
    onClickLeft() {
      Bus.$emit(Bus.SHOW_LOOP_FLOATING, false)
      this.$router.push({
        path: '/topic'
      })
    },
    /**
     * 滚动函数
     */
    scrollHandle() {
      var subHeight = 0
      if (document.getElementById('foot_loop_box')) {
        subHeight = document.getElementById('foot_loop_box').offsetHeight
      }
      if (document.getElementById('foot-open-box')) {
        subHeight = document.getElementById('foot-open-box').offsetHeight
      }
      // 推荐列表
      if(this.$refs.recomendListBox && this.$refs.recomendListBox.$refs.advListItem) {
        var advList = this.$refs.recomendListBox.$refs.advListItem
        this.countExposure(advList, subHeight)
      }
      
      // 推荐阅读的上面
      var recomentList = this.$refs.recomentAdvListItem
      if (recomentList != undefined) {
        this.countExposure([recomentList], subHeight)
      }
    },
    /**
     * 计算曝光
     */
    countExposure(advList, subHeight) {
      if (advList) {
        for (let item of advList) {
          // 先存距离顶部的距离和高度
          if (!this.advMap[item['id']]['offset']) {
            this.advMap[item['id']]['offset'] = {
              offsetTop: item.offsetTop,
              offsetHeight: item.offsetHeight
            }
          }
          let itemOffTop = item.getBoundingClientRect().top + window.scrollY
          let itemOffHeight = item.offsetHeight
          // 需要剪掉底部的广告轮播外加只有大于60%显示才算在可见区域（算一次曝光）
          if (itemOffTop >= (window.scrollY - itemOffHeight + (itemOffHeight * 0.6))
            && itemOffTop < (window.scrollY + window.innerHeight - subHeight - (itemOffHeight * 0.6))) {
            // 第一次记录时间
            if (!this.advMap[item['id']]['time']) {
              this.advMap[item['id']]['time'] = new Date().getTime()
              var itemId = item['id']
              setTimeout(() => {
                if (itemOffTop >= (window.scrollY - itemOffHeight + (itemOffHeight * 0.6))
                  && itemOffTop < (window.scrollY + window.innerHeight - subHeight - (itemOffHeight * 0.6))) {
                  if (!this.advMap[itemId]['isExposure']) {
                    this.advExposureAdd(this.advMap[itemId])
                  }
                  // 标记曝光
                  this.advMap[itemId]['isExposure'] = true
                }
              }, 1000)

            } else {

              // 曝光时长大于或等于1秒
              var current = new Date().getTime()
              if (current - this.advMap[item['id']]['time'] >= 1000) {
                if (!this.advMap[item['id']]['isExposure']) {
                  this.advExposureAdd(this.advMap[item['id']])
                }
                // 标记曝光
                this.advMap[item['id']]['isExposure'] = true
              }
            }

            // 不在可见区域的时候需要把时间置为null,是否曝光置为false
          } else {
            this.advMap[item['id']]['time'] = null
            this.advMap[item['id']]['isExposure'] = false
          }
        }
      }
    },
    /**
     * 请求曝光接口
     */
    advExposureAdd(item) {
      this.$api.queryAdvAddOne({ adId: item.id }).then(res => {}).catch(err => {})
    },
    /**
     * 图片错误处理
     */
    errorImgShow(id) {
      this.advMap[id].isShow = false
    },
    /**
     * 打开广告地址
     */
    openAdvLink(url) {
      window.open(url)
    },
    /**
     * 视频播放记录上报
     */
    async postVideoPlayView() {
      let params = {
        id:this.noteInfo.id,
        playTime: this.videoPlayTime
      }
      await this.$api.postVideoPlay(params)
    },
    /**
     * 获取关联商品
     */
    async getTaskInfo() {
      let { data: res } = await this.$api.getGoodsInfo({articleId: this.$route.params.id});
      if(res.code == 200 && res.data) {
        this.goodsData = res.data;
        this.goodsData.price = this.goodsData.price.toString();
      }
    },
    /**
     * 打开关联商品详情
     */
    toGoodsDetail(url) {
      this.$api.postGoodsClick({
        articleId: this.$route.params.id,
        goodsId: this.goodsData.goodsId,
        goodsName: this.goodsData.goodsName
      }).then((res)=>{})
      window.open(url);
    }
  }
}
</script>

<style lang='less'>
.news-detail {
  word-wrap: break-word;
  overflow: hidden;
  .adv-read-comment-pre {
    padding: 14px 15px;
    border-top: 10px solid #F5F5F4;
    .adv-read-comment-title {
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      font-size: 17px;
      font-family: Source Han Sans, Source Han Sans-Regular;
      font-weight: 500;
      color: #111111;
      line-height: 24px;
    }

    .adv-read-comment-img {
      position: relative;
      overflow: hidden;
      border-radius: 4px;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .adv-read-comment-tip {
        display: flex;
        align-items: center;
        position: absolute;
        top: 10px;
        right: 10px;
        height: 20px;
        line-height: 20px;
        padding: 0 8px;
        border: 1px solid white;
        border-radius: 12px;
        background: rgba(0, 0, 0, 0.3);
        font-size: 11px;
        color: white;
      }
    }
  }
}
</style>