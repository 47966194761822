<template>
  <div>
    <van-nav-bar @click-left='onClickLeft' @click-right='onClickRight'>
      <template #left>
        <div class='homeNewHead'>
          <img :src='$baseInfo.LOGO_IMG' alt=''>
          <span>凯迪网首页</span>
        </div>
      </template>
      <template #right>
        <div class='shareNewHead'>打开</div>
      </template>
    </van-nav-bar>
  </div>
</template>

<script>
export default {
  methods:{
    onClickLeft() {
      this.$emit('on-click-left')
    },
    onClickRight() {
      this.$emit('on-click-right')
    }
  }
}
</script>

<style lang="less" scoped>
.homeNewHead {
  display: flex;
  justify-content: left;
  align-items: center;

  img {
    width: 39px;
    height: 39px;
  }

  span {
    font-size: 13px;
    font-weight: 400;
    text-align: left;
    color: #3a3e45;
    margin-left: 7px;
  }
}

.shareNewHead {
  width: 60px;
  padding: 7px 0;
  background: #F7321C;
  border-radius: 4px;
  color: #fff;
  font-size: 11px;
}
</style>