var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.pictures.length)?_c('div',{ref:"photosWell",class:[
    'photos-well', 
    {
        'photos-well-radius': _vm.radius,
        'warp-cover': _vm.pictures.length == 1,
        'warp-between': _vm.pictures.length >= 3 && _vm.pictures.length != 4,
        'photos-well-two': _vm.pictures.length == 2 || _vm.pictures.length == 4,
        'photos-well-special': _vm.pictures.length >= 5,
        'photos-well-special-two': _vm.pictures.length > 4 && (_vm.pictures.length % 3 === 1),
        'photos-well-special-three': _vm.pictures.length > 4 && (_vm.pictures.length % 3 === 2),

        'photos-well-special-four': _vm.pictures.length == 4,
    }
],on:{"click":function($event){return _vm.pictureHandler($event)}}},_vm._l((_vm.pictures),function(item,index){return _c('div',{key:index,staticClass:"photos-well-item"},[_c('img',{staticClass:"pwi-img",attrs:{"height":_vm.height,"data-index":index,"data-item":"item","src":item || 'https://cdn.9kd.com/kdnet/993674ad7f2b41418b6090874f069d4c.png',"alt":""}})])}),0):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }