<template>
  <div class='news-wrap' v-if='recommend.length'>
    <div class='news-wrap-head'>
      <span class='s1'></span>
      <span class='s2'>推荐阅读</span>
    </div>
    <div v-for='(item,index) in recommend' :key='index' :class="[{'adv-list-item':item.isAdv}]">
      <div v-if="item.isAdv && recommendRequest && advMap[item.onlyId]['isShow']" class='recommend-wrap'
            @click='openAdvLink(item.adUrl)'>
        <div class='dis-flex' v-bind='{ id: item.onlyId }' ref='advListItem'>
          <div class='rw-left'>
            <p class='p1 text-ellipsis'>{{ item.title }}</p>
            <p class='p-holder'></p>
            <p class='p2 dis-flex'><span class='adv-tip'>广告</span></p>
          </div>
          <div class='rw-right'>
            <img class='adv-img' :src="item.pictures[0] ? item.pictures[0] : ''" @error='errorImgShow(item.onlyId)'
                  alt=''>
          </div>
        </div>
      </div>
      <div v-else-if='!item.isAdv' class='recommend-wrap'
            @click='queryoneAndShowOpen(item, articledetailrecommend, index)'>
        <div :class="['rw-left',{'width-100':!item.firstPicture}]">
          <p :class="['p1',{'text-ellipsis':item.firstPicture}]">{{ item.title }}</p>
          <p class='p-holder'></p>
          <p class='p2'>
            <img v-if='index < (existAdv ? 4 : 3) ' class='open-app-label'
                  src='https://cdn.9kd.com/kdnet/kd-wap-limit-label.png'>
            <span class='s1' :class="{'max-len':item.firstPicture}"> {{ item.author.nickname }} </span>
            <span class='s2'> {{ item.createTime | compareDate }} {{ item.comments ? (item.comments + '评论') : ''
              }} </span>
          </p>
        </div>
        <div v-if='item.firstPicture' class='rw-right'>
          <img class='article-img' :src='item.firstPicture' alt=''>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from '../utils/bus';
export default {
  props: {
    recommend: {
      type: Array,
      default: () => []
    },
    recommendRequest: {
      type: Boolean,
      default: false
    },
    advMap:{
      type: Object,
      default: () => {}
    },
    existAdv: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      articledetailrecommend: Bus.CONTINUTETYPE_ARTICLE_DETAIL_RECOMMEND,
    }
  },
  methods: {
    openAdvLink(url) {
      this.$emit('openAdvLink', url)
    },
    queryoneAndShowOpen(noteInfo, continuteType, index) {
      this.$emit('queryoneAndShowOpen', noteInfo, continuteType, index)
    },
    errorImgShow(id) {
      this.$emit('errorImg', id)
    }
  }
}
</script>
<style lang="less" scoped>
  .news-wrap {
    box-sizing: border-box;
    padding: 20px 15px;
    border-top: 10px solid #F5F5F4;
    font-family: PingFang SC, PingFang SC-Regular;
    .news-wrap-head {
      display: flex;
      align-items: center;
      .s1 {
        width: 3px;
        height: 18px;
        background: #F7321C;
        margin-right: 8px;
      }
      .s2 {
        font-weight: 600;
        font-size: 16px;
        color: #3a3e45;
        letter-spacing: 1px;
      }
    }
    .recommend-wrap {
      display: flex;
      justify-content: space-between;
      font-family: PingFang SC, PingFang SC-Regular;
      padding: 14px 0;
      border-bottom: 1px solid #f6f6f6;
      .rw-left {
        text-align: left;
        width: 78%;
        display: flex;
        flex-direction: column;
        .p1 {
          font-size: 17px;
          font-family: Source Han Sans, Source Han Sans-Regular;
          font-weight: 400;
          text-align: left;
          color: #111111;
          line-height: 24px;
          letter-spacing: 1px;
        }
        .text-ellipsis {
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        .p-holder {
          flex: 1;
        }
        .p2 {
          font-size: 12px;
          color: #afb5bf;
          margin-top: 5px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .s1 {
            margin-right: 5px;

            &.max-len {
              flex-shrink: 1;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .s2 {
            margin-right: 5px;
            flex-shrink: 0;
          }
          .open-app-label {
            width: 77px;
            height: 21px;
            margin-right: 8px;
          }
        }
        .adv-tip {
          display: flex;
          align-items: center;
          height: 16px;
          padding: 0px 3px 0 3px;
          border: 1px solid #e4e4e4;
          border-radius: 2px;
          font-size: 11px;
        }
      }
      .rw-right {
        flex-shrink: 0;
        width: 114px;
        height: 78px;
        overflow: hidden;
        border-radius: 4px;

        .article-img, .adv-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .width-100 {
        width: 100%;
      }
    }
    .dis-flex {
      flex: 1;
      display: flex;
      justify-content: space-between;
    }
  }
</style>