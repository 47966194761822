<template>
  <div class="article-content" ref='newsInfo'>
    <p class='news-title' v-html="noteInfo.title && $replaceRegular(noteInfo.title)"></p>
    <div class='is-News' v-if="noteInfo.author.product === 'net'">
      <p class='news-time'>
        <span>{{ noteInfo.author.nickname }}</span> ·
        <span>{{ noteInfo.createTime | compareDate }}</span> ·
        <span>{{ noteInfo.ipLocation }}</span>
        <span style='float: right'>展现{{ noteInfo.views }}</span>
      </p>
    </div>
    <div class='is-note' v-if="noteInfo.author.product !== null && noteInfo.author.product !== 'net'">
      <div class='in-left'>
        <div class='avatar-images' @click='showOpenSelDialog(noteInfo, author)'>
          <img :src="noteInfo.author.avatar ? noteInfo.author.avatar : $baseInfo.DEFAULT_AVATAR">
          <img v-if='noteInfo.author.certify && noteInfo.author.field' class='vip-img' :src="$baseInfo.AUTHER_IMAGE" alt>
        </div>
        <div class='avatar-info' @click='showOpenSelDialog(noteInfo, author)'>
          <p class='p1'>
            {{ noteInfo.author.nickname }}
            <img v-if='noteInfo.author.product' :src="$baseInfo.AUTHER_SMALL_IMAGE" />
          </p>
          <p class='p2' v-if='noteInfo.createTime'>
            {{ noteInfo.createTime | compareDate }} 
            <span style="margin: 0 5px">{{ noteInfo.ipLocation }}</span>
            <span>展现{{ noteInfo.views }}</span>
          </p>
        </div>
      </div>
      <div class='in-right'>
        <button tag='button' @click='showOpenSelDialog(noteInfo, author)'>+关注</button>
      </div>
    </div>
    <div v-if="newOrNote === 2 && noteInfo.author.product === 'net' && noteInfo.abstracte && !delHtmlTag(this.noteInfo.content).includes(noteInfo.abstracte)">
      <span class='news-abstract' v-html="'摘要: ' + $replaceRegular(noteInfo.abstracte)"></span>
    </div>
    <div ref='textContent' :class="['news-content', {'show-height':shortdiv}]" v-html='noteInfo.content'></div>
    <!-- 关联商品 -->
    <goods-card :style="{'margin-top': shortdiv ? '10px' : ''}"
      :goodsData="goodsData"
      @to-goods-detail="toGoodsDetail">
    </goods-card>
    
    <!-- 标签 -->
    <label-content :labelData="noteInfo.contentLabels" @clickHandler="openAppLabel"></label-content>

    <div class='news-views-kind' v-show='!shortdiv'>
      <span v-show='!shortdiv' class='news-kind'>{{ noteInfo.kind === 1 ? '原创' : '转载' }}</span>
      <span v-if='showOrHideChangeTime' class='news-kind-time'>修改于{{noteInfo.updateTimeStr}}</span>
    </div>
    <div v-if='!shortdiv && noteInfo.author.platformUser'>
      <div class="dividing-line"></div>
      <div class="declaration">本文为凯迪网自媒体“凯迪号”作者上传发布，代表其个人观点与立场，凯迪网仅提供信息发布与储存服务。文章内容之真实性、准确性由用户自行辨别，凯迪网有权利对涉嫌违反相关法律、法规内容进行相应处置。</div>
    </div>
    <div class='news-open' ref='newsOpen' v-if='shortdiv'>
      <div :class="{ 'content-mask': showMask }"></div>
      <div class='open-ico' @click='showOpenSelDialog(noteInfo, lookarticle)'><span class='font'>展开全文</span>
        <span class='iconfont ico'>&#xe65d;</span>
      </div>
      <div class='open-app' @click='showOpenSelDialog(noteInfo, article)'>
        <button>打开凯迪网APP，阅读体验更佳</button>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from '../utils/bus';
import { ImagePreview } from 'vant'
export default {
  props:{
    noteInfo: {
      type: Object,
      default: () => {author: {}}
    },
    newOrNote:{
      type: Number,
      default: 1
    },
    goodsData:{
      type: Object,
      default: () => null
    },
  },
  data() {
    return {
      author: Bus.CONTINUTETYPE_AUTHOR,
      lookarticle: Bus.CONTINUTETYPE_LOOK_ARTICLE,
      article: Bus.CONTINUTETYPE_ARTICLE,
      shortdiv: false,
      showMask: false
    }
  },
  components:{
    GoodsCard : () => import(/* webpackChunkName: "GoodsCard" */'./GoodsCard'),
    LabelContent: () => import(/* webpackChunkName: "GoodsCard" */'./LabelContent')
  },
  computed: {
    showOrHideChangeTime() { // 是否显示文章更新时间
      if (this.noteInfo.updateTime !== this.noteInfo.createTime) {
        return true
      } else {
        return false
      }
    }
  },
  beforeDestroy() {
    if(this.$refs.newsInfo) {
      this.$refs.newsInfo.removeEventListener('click', this.preventHref)
    }
  },
  mounted() {
    if(this.$refs.newsInfo) {
      this.$refs.newsInfo.addEventListener('click', this.preventHref)
    }
  },
  methods:{
    /**
     * 点击标签
     */
    openAppLabel(labelName) {
      this.$emit('clickHandler', labelName)
    },
    /**
     * 匹配摘要
     */
    delHtmlTag(str) {
      if (!str) {
        return 'empty'
      }
      return str.replace(/<[^>]+>/g, '')//去掉所有的html标记
    },
    /**
     * 去商品详情页
     */
    toGoodsDetail(url) {
      this.$emit('toGoodsDetail', url);
    },
    /**
     * 打开APP和继续阅读文章
     */
    showOpenSelDialog(noteInfo, continuteType) {
      // 继续阅读
      if(continuteType == this.lookarticle) {
        this.shortdiv = false
        this.showMask = false
      }else {
        this.$emit('showOpenApp', noteInfo, continuteType);
      }
    },
    /**
     * 全部加载图片完成才显示打开凯迪网按钮
     */
    setImageMore() {
      let index = 0
      let imgArr = this.$refs.textContent.querySelectorAll('img.refimgs');
      let len = 0
      let images = []
      if (imgArr.length > 0) {
        let self = this
        for (let i=0;i<imgArr.length;i++) {
          if (imgArr[i].src) {
            len++
            images.push(imgArr[i].src)
            // 点击预览图片
            imgArr[i].addEventListener('click',function() {
              ImagePreview({
                images,
                startPosition: i,
                loop: false,
                onScale: function(o) {
                  if (o.scale < 0.4) {
                    this.close()
                  }
                }
              })
            })
          }
          imgArr[i].onload = function() {
            index++
            if (index >= len) {
              self.continueFnc()
            }
          }
          imgArr[i].onerror = function() {
            index++
            if (index >= len) {
              self.continueFnc()
            }
          }
        }
      } else {
        this.continueFnc()
      }
    },
    /**
     * 判断是否显示【展开全文】按钮
     */
    continueFnc() {
      let height = this.$refs.textContent.offsetHeight
      if (height > 545) {
        this.showMask = true
        this.shortdiv = true
      } else {
        this.showMask = false
        this.shortdiv = false
      }
    },
    preventHref(event) {
      if (this.$Is_IOS) {
        if (event.target.nodeName == 'A') {
          if (event.target.href != '' && event.target.href.indexOf('9kd.com') >= 0) {
            var url = event.target.href
            event.preventDefault()
            if (event.target.href.indexOf('www.9kd.com') >= 0) {
              var index = event.target.href.lastIndexOf('\/')
              url = '/note/' + url.substring(index + 1, url.length)
            }
            if (event.target.href.indexOf('m.9kd.com') >= 0) {
              url = url.replace(/^https:\/\/[^/]+/, '')
            }
            this.$router.push({
              path: url
            })
          }
        }
      }
    },
  }
}
</script>
<style lang="less">
  .article-content {
    box-sizing: border-box;
    padding: 3px 13px 24px 13px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: left;
    .dividing-line {
      margin: 10px 0 0 0;
      height: 20px;
      overflow: hidden;
      background: url(https://cdn.9kd.com/kdnet/diagonal.png) repeat-x;
      background-size: 7px 9px;
    }
    .declaration{
      font-size: 14px;
      color: #BEBEBE;
      line-height: 22px;
      text-align: left;
    }
    .news-title {
      font-size: 24px;
      font-weight: bold;
      color: #242831;
      letter-spacing: 1px;
    }

    .is-News {
      .news-time {
        font-size: 13px;
        color: #9099a6;
        margin: 10px 0 5px 0;
        letter-spacing: 1px;
      }
    }

    .is-note {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      height: 28px;
      font-weight: 400;
      margin-bottom: 8px;

      .in-left {
        display: flex;
        align-items: center;
        font-size: 13px;
        color: #9099a6;
        letter-spacing: 1px;

        .avatar-images {
          width: 28px;
          height: 28px;
          border-radius: 50%;
          margin-right: 10px;
          position: relative;

          img {
            width: 28px;
            height: 28px;
            border-radius: 50%;
          }

          .vip-img {
            width: 14px;
            height: 14px;
            position: absolute;
            bottom: 0;
            right: -3px;
          }
        }

        .avatar-info {
          color: #3a3e45;
          font-size: 13px;

          .p1 {
            display: flex;
            align-items: center;

            img {
              margin-left: 5px;
              width: 20px;
              height: auto;
            }
          }

          .p2 {
            font-size: 10px;
            color: #9099a6;
          }
        }
      }

      .in-right {
        display: flex;
        align-items: center;

        button {
          width: 54px;
          height: 26px;
          background: #F7321C;
          border-radius: 13px;
          outline: none;
          border: none;
          font-size: 11px;
          color: #ffffff;
          letter-spacing: 1px;
        }
      }
    }

    .news-abstract {
      width: calc(100vw - 22px - 26px);
      margin-top: 6px;
      padding: 11px;
      font-size: 13px;
      color: #8C94A3;
      display: inline-block;
      text-align: justify;
      text-align-last: left;
      background: #F3F6F9;
    }
    .news-content {
      font-size: 16px;
      color: #414449;
      letter-spacing: 1px;
      position: relative;
      box-sizing: border-box;
      padding-top: 8px;

      .aliyun-replay-show_duration {
        width: 64px;
        height: 64px;
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 999;
        text-align: center;
        line-height: 64px;
        color: #fff;
      }

      .aliyun-replay-btn {
        position: absolute;
        z-index: 100;
        cursor: pointer;
        background: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;

        .aliyun-replay-icon {
          text-align: center;
          .iconfont {
            color: white;
            font-size: 25px;
          }

          .aliyun-replay-text {
            color: white;
            font-size: 13px;
            background: rgba(0, 0, 0, 0.2);
            border-radius: 4px;
            border: 1px solid #ccc;
            padding: 4px 10px 5px 10px;
          }
        }
      }

      ul {
        max-width: 100% !important;
        padding: 0px !important;

        * {
          max-width: 100% !important;
          padding: 0px !important;
        }
      }

      p {
        margin-bottom: 15px;
        line-height: 25px;
      }

      img {
        width: 100% !important;
        height: auto !important;
      }

    }
    .news-views-kind {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      .news-kind {
        padding: 2px 11px;
        color: #9099A6;
        font-size: 12px;
        border-radius: 11px;
        background: #F3F6F9;
        vertical-align: center;
      }
      .news-kind-time {
        height: 25px;
        line-height: 25px;
        padding-left: 11px;
        padding-right: 11px;
        color: #ABB2BC;
        font-size: 12px;
        vertical-align: center;
      }
    }
    .show-height {
      max-height: 545px;
      overflow: hidden;
    }

    .news-open {
      text-align: center;
      position: relative;

      .content-mask {
        width: 100%;
        height: 34px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(255, 255, 255, 0) 4%, #ffffff);
        position: absolute;
        left: 0;
        top: -33px;
      }

      .open-ico {
        z-index: 999;
        text-align: center;
        height: 16px;
        line-height: 5px;
        color: #9099a6;

        .font {
          font-size: 14px;
        }

        animation: shake 1000ms infinite;
      }

      .open-app {
        margin-top: 20px;
        font-size: 13px;
        color: #F7321C;
        letter-spacing: 1px;

        button {
          height: 40px;
          padding: 0 10px;
          border: 1px solid #F7321C;
          border-radius: 20px;
          background: #ffffff;
        }
      }
    }
  }
</style>
<style>
@keyframes shake {
  from {
    transform: translateY(0px);
  }
  20% {
    transform: translateY(2px);
  }
  40% {
    transform: translateY(4px);
  }
  60% {
    transform: translateY(6px);
  }
  80% {
    transform: translateY(8px);
  }
  100% {
    transform: translateY(10px);
  }
  80% {
    transform: translateY(8px);
  }
  60% {
    transform: translateY(6px);
  }
  40% {
    transform: translateY(4px);
  }
  to {
    transform: translateY(0px);
  }
}
</style>