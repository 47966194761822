let storage = {
  set(key, value) {
    if (typeof window === 'undefined') {
      return ''
    }
    localStorage.setItem(key, JSON.stringify(value));
  },
  get(key) {
    if (typeof window === 'undefined') {
      return ''
    }
    return JSON.parse(localStorage.getItem(key));
  },
  remove(key) {
    if (typeof window === 'undefined') {
      return ''
    }
    localStorage.removeItem(key);
  }
};
export default storage;
